import './App.css';
import { useEffect, useState } from "react";
import { useSpring, animated, useTrail } from '@react-spring/web'
import Select from "react-select"

function StartScreen(props) {
  const [springs, api] = useSpring(() => ({
    from: {x: 0, opacity: 1}
  }))

  const handleClick = () => {
    api.start({
      from: {
        opacity: 1,
        x: 0,
      },
      to: {
        opacity: 0,
        x: -300,
      },
      config: {duration: 300 },
      onRest: () => props.setStarted(true)
    });

  }

  return (
    <div className="flex center col fullheight">
      <animated.div className="content-container half-width text-center flex col gap-def" style={springs} >
        <h1 className="hm">Create your brand claim!</h1>
        <p>You want a kick-ass brand or product claim, but you got no experience in copy writing nor a big budget for it? 🤔</p>
        <p>Then click below and let our GTMN brand creator design you inspiring claims that resonate with your target group.</p>
        <div className="flex center">
          <div className="button inline m-t-20" onClick={() => handleClick()}>Click to Start</div>
        </div>
      </animated.div>
    </div>
  )
}

const CommunicationStyleSelect = (props) => {

  const options = [
    {value: "Craft something new", label: "Craft something new"},
    {value: "Care for others", label: "Care for others"},
    {value: "Exert control", label: "Exert control"},
    {value: "Have a good time", label: "Have a good time"},
    {value: "Be OK just as they are", label: "Be OK just as they are"},
    {value: "Find and give love", label: "Find and give love"},
    {value: "Act courageously", label: "Act courageously"},
    {value: "Break the rules", label: "Break the rules"},
    {value: "Affect transformation", label: "Affect transformation"},
    {value: "Retain or renew faith", label: "Retain or renew faith"},
    {value: "Maintain independence", label: "Maintain independence"},
    {value: "Understand their world", label: "Understand their world"},
  ]

  return ( 
    <div className="flex col center">
      <div className="select-container">
        <Select options={options} onChange={(e) => props.setChoice(e.label)} styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "2px solid white",
            backgroundColor: "rgba(0,0,0,0)",
            color: "white",
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "rgba(109,1,209,1)",
            color: "white",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? "rgba(244,69,252,1)" : "rgba(109,1,209,1)", 
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: "white",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: "white",
          }),

        }}/>
      </div>
      <div className="flex center">
        <input className="button m-t-20" type="submit" value="Generate!"/>
      </div>
    </div>
   );
}

function QuestionnaireSlide(props) {
  const [choice, setChoice] = useState(null)
  const [springs, api] = useSpring(() => ({
    from: {x: 200, opacity: 0},
    to: {x: 0, opacity: 1},
    config: {duration: 300 },
    reset: props.slideIndex,
  }))

  const handleSubmit = () => {
    api.start({
      from: {
        opacity: 1,
        x: 0,
      },
      to: {
        opacity: 0,
        x: -200,
      },
      config: {duration: 300 },
      onRest: () => props.onStepSubmit(choice),
    });
  }

  
  return (
    <animated.div className="content-container text-center flex col gap-def" style={springs}>
      <h1 className="question">{props.content.question}</h1>
      {props.content.tooltip && <p>{props.content.tooltip}</p>}
      <form action="" onSubmit={(e) => {e.preventDefault(); handleSubmit()}}>
        {props.content.inputType !== "communication-select" 
        ? (props.content.inputType === "text" 
          ? <input type="text" value={choice} onChange={(e) => setChoice(e.target.value)}/> 
          : <textarea value={choice} onChange={(e) => {setChoice(e.target.value);}}/>)
        : <CommunicationStyleSelect setChoice={setChoice} />}
        {props.content.inputType !== "communication-select" && <div className="flex center">
          <input className="button m-t-40" type="submit" value="Continue"/>
        </div>}
      </form>
    </animated.div>
  )

}


function Questionnaire (props) {
  const [step, setStep] = useState(0)
  const [choices, setChoices] = useState([])
  const [finished, setFinished] = useState(false)

  const slideContent = [
    {question: "What product are you building?", tooltip: "e.g. Robot Vacuum", inputType: "text"},
    {question: "What are your standout features?", tooltip: "e.g. automatic floor plan, built-in wiping, intelligent dirt-type recognition", inputType: "textarea"},
    {question: "Your brand name?", tooltip: "e.g. iRobot", inputType: "text"},
    {question: "What does your product help people achieve?", tooltip: null, inputType: "communication-select"}
  ]

  const onStepSubmit = (choice) => {
    setChoices((currChoices) => [].concat(currChoices, [choice]));
    if(step !== 3){
      setStep((currStep) => setStep(currStep+1))
    } else {
    }
  };

  useEffect(() => {

    if(choices.length === 4 && !finished){
      /* Build the object for the API */
  
      const finalizedChoices = {
        product: choices[0],
        features: choices[1],
        brandname: choices[2],
        communication_style: choices[3]
      }
  
      setFinished(true)
      props.setQueryObject(finalizedChoices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices]);

  return (
    <div className="flex center col fullheight">
      <QuestionnaireSlide key={step} content={slideContent[step]} onStepSubmit={onStepSubmit}/>
    </div>
  )
}

function Loadscreen() {
  return(
    <div className="flex center col fullheight">
      <p>One moment, we're generating your results...</p>
      <span class="loader"></span>
    </div>
  )
}

function ResultScreen(props){
  const [splitResult, setSplitResult] = useState([])

  const trails = useTrail(splitResult.length, {
    from: {y: 200, opacity: 0},
    to: {y: 0, opacity: 1},
    config: {duration: 500 },
    delay: 2000,
  })

  useEffect(() => {
    const split_res = props.result.split("\n")

    if(splitResult !== split_res){
      setSplitResult(split_res)
    }

/*     if (splitResult.length === 0){
      split_res.forEach((r) => {
        setTimeout(() => {
          setSplitResult((curr_r) => [].concat(curr_r, [r]))
        }, 2000)
      })
    }; */
  }, [props.result, splitResult]);

  return(
    <div className="flex center col fullheight">
      {trails.map((styles, i) => (
          <animated.div className="result-container" style={styles} key={i}>
            <h2 className="result">{splitResult[i]}</h2>
          </animated.div>
      ))}
    </div>
  )
}

function App() {
  const [started, setStarted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [queryObject, setQueryObject] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    if(queryObject && !submitting && !result){
      setSubmitting(true)
      const URL = "https://gtmn-brandclaimgenerator-backend-yjt7gpz7jq-ey.a.run.app/generate"

      const options = {
        method: "POST",
        body: JSON.stringify(queryObject),
        headers: {
          'Content-Type': 'application/json'
        }
      }

      fetch(URL, options)
        .then(res => res.json())
        .then(data => {setResult(data); setSubmitting(false)})
    }


  }, [queryObject, submitting, result]);

  return (
    <div className="App">
      {!started ? <StartScreen setStarted={setStarted}/> : 
      submitting ? <Loadscreen /> : 
      result ? <ResultScreen result={result}/> :
      <Questionnaire setQueryObject={setQueryObject}/>}
    </div>
  );
}

export default App;
